import React, {useState} from 'react';
import { graphql, useStaticQuery, Link } from "gatsby"

const MobileHeader = () => {
  const [expanded, setExpanded] = useState(false)

  const data = useStaticQuery(graphql`
  query {
    wbLogoMobile: file(relativePath: { eq: "logo2018.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 65) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    hamburger: file(relativePath: { eq: "menu-icon.png" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 35) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`)

  const {wbLogoMobile, hamburger} = data;

  return (
    <>
    <div className="md:hidden bg-white font-bold text-green-800 font-serif text-center shadow-lg fixed w-full text-sm flex items-center border-t-8 border-green-800 z-40">
        <div className="border-r border-gray-400 p-2">
          <Link to="/"><img src={wbLogoMobile.childImageSharp.fluid.src} alt="Willow Branch Flowers and Design Logo" /></Link>
        </div>
        <div className="text-center flex-grow p-2">
          Willow Branch Flowers {expanded}
        </div>
        <div className="border-l border-gray-400 py-2 px-3">
          <button onClick={() => setExpanded(!expanded)}><img src={hamburger.childImageSharp.fluid.src} alt="Mobile Menu Toggle" className="opacity-75" /></button>
        </div>
    </div>
    <div className={`${ expanded ? `block` : `hidden` } w-full bg-green-900 text-white absolute top-0 left-0 h-screen z-50`}>
      <button className="w-full text-right p-4 pr-8 font-bold text-3xl" onClick={() => setExpanded(!expanded)}>x</button>
      <ul className={`font-serif tracking-wider`}>
        <li><Link className="text-center block py-2 px-4 mb-8 text-2xl rounded text-white font-bold hover:bg-green-800" to="/">Home</Link></li>
        <li><Link className="text-center block py-2 px-4 mb-8 text-2xl rounded text-white font-bold hover:bg-green-800" to="/brides">Our Work</Link></li>
        <li><Link className="text-center block py-2 px-4 mb-8 text-2xl rounded text-white font-bold hover:bg-green-800" to="/testimonials">Testimonials</Link></li>
        <li><Link className="text-center block py-2 px-4 mb-8 text-2xl rounded text-white font-bold hover:bg-green-800" to="/about">About</Link></li>
        {/* <li><Link className="text-center block py-2 px-4 mb-8 text-2xl rounded text-white font-bold hover:bg-green-800" to="/contact">Contact</Link></li> */}
      </ul>
    </div>
    </>
  )
  }
export default MobileHeader;
