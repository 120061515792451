/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import "../css/site.css"
import MobileHeader from "./mobileheader"
import Helmet from "react-helmet"

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent
    ? {
        className:
          "inline-block py-2 px-4 ml-8 rounded text-white font-bold bg-green-800",
      }
    : null
}

const FASC = () => {
  return (
    <div className="bg-blue-100 text-blue-600 text-center p-4 shadow-md text-sm md:text-base">
      <p>Thank you for your interest in our floral services. After 20 years of creating beautiful weddings, we are stepping away from flowers at the end of 2022 to concentrate on our newest venture     <a href="https://www.fieldsatskycrest.com"
    target="_blank"
    rel="noopener noreferrer" className="font-bold"> Fields at Skycrest</a> for any questions or floral recommendations, contact
    <a href="mailto:stacey@fieldsatskycrest.com"
    target="_blank"
    rel="noopener noreferrer" className="font-bold"> stacey@fieldsatskycrest.com  </a>

    Here's to a new chapter in our wedding story! All the best, Stacey</p>
</div>
  )
}

const HeaderNav = ({ noFlex }) => {
  return (
    <ul className={`${noFlex ? "" : "flex"} flex font-serif tracking-wider`}>
      <li>
        <Link
          className="inline-block py-2 px-4 ml-8 rounded text-white font-bold hover:bg-green-800"
          activeClassName="bg-green-800"
          to="/"
        >
          Home
        </Link>
      </li>
      <li>
        <Link
          getProps={isPartiallyActive}
          className="inline-block py-2 px-4 ml-8 rounded text-white font-bold hover:bg-green-800"
          activeClassName="bg-green-800"
          to="/brides"
        >
          Our Work
        </Link>
      </li>
      <li>
        <Link
          className="inline-block py-2 px-4 ml-8 rounded text-white font-bold hover:bg-green-800"
          activeClassName="bg-green-800"
          to="/testimonials"
        >
          Testimonials
        </Link>
      </li>
      <li>
        <Link
          className="inline-block py-2 px-4 ml-8 rounded text-white font-bold hover:bg-green-800"
          activeClassName="bg-green-800"
          to="/about"
        >
          About
        </Link>
      </li>
      <li>
        {/* <Link
          className="inline-block py-2 px-4 ml-8 rounded text-white font-bold hover:bg-green-800"
          activeClassName="bg-green-800"
          to="/contact"
        >
          Contact
        </Link> */}
      </li>
    </ul>
  )
}

const Layout = ({ isHome, headerImage, children }) => {
  const data = useStaticQuery(graphql`
    query {
      testimonials: allSanityTestimonial {
        nodes {
          customer
          quote
        }
      }
      desktop: file(relativePath: { eq: "homepage_panel_01.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      wbLogo: file(relativePath: { eq: "logo2018.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 350) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      knot2018: file(relativePath: { eq: "knot-2018.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      knot2019: file(relativePath: { eq: "knot-2019.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      knot2020: file(relativePath: { eq: "knot-2020.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      knot2020hof: file(relativePath: { eq: "knot-2020-hof.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ww2018: file(relativePath: { eq: "ww-2018.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ww2019: file(relativePath: { eq: "ww-2019.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ww2020: file(relativePath: { eq: "ww-2020.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const {
    desktop,
    knot2018,
    knot2019,
    ww2018,
    ww2019,
    ww2020,
    knot2020,
    knot2020hof,
    testimonials,
    wbLogo,
  } = data

  const shuffled = testimonials.nodes.sort(() => 0.5 - Math.random())
  let randomTestimonials = shuffled.slice(0, 2)

  return (
    <div className="md:h-screen md:flex flex-col">
      <Helmet
        bodyAttributes={{
          class: "font-sans antialiased bg-green-800 text-gray-800",
        }}
      />
      <header className="bg-white">
        {isHome ? (
          <>
          <FASC />
          <BackgroundImage
            Tag="div"
            className="md:h-screen md:flex flex-col"
            fluid={desktop.childImageSharp.fluid}
            backgroundColor={`#040e18`}
          >
            <div className="bg-vignette flex-grow md:pt-8">
              <MobileHeader />
              <div className="container mx-auto hidden md:flex items-center">
                {/* <img src={wbLogo.childImageSharp.fluid.src} alt="Willow Branch Flowers and Design Logo"/> */}
                <nav className="container mx-auto justify-end flex">
                  <HeaderNav />
                </nav>
              </div>
            </div>
            <div className="container mx-auto pb-12 md:pb-24 pt-24 md:pt-0">
              <ul className="flex list-reset justify-end mr-4 md:mr-0">
                <li className="flex-grow">
                  <img
                    src={wbLogo.childImageSharp.fluid.src}
                    alt="Willow Branch Flowers and Design Logo"
                  />
                </li>
                <li className=" ml-4 md:ml-0 md:mr-6">
                  <img
                    src={ww2018.childImageSharp.fluid.src}
                    alt="Wedding Wire Couple's Choice Award 2018"
                  />
                </li>
                <li className=" ml-4 md:ml-0 md:mr-6">
                  <img
                    src={ww2019.childImageSharp.fluid.src}
                    alt="Wedding Wire Couple's Choice Award 2019"
                  />
                </li>
                <li className=" ml-4 md:ml-0 md:mr-6">
                  <img
                    src={ww2020.childImageSharp.fluid.src}
                    alt="Wedding Wire Couple's Choice Award 2020"
                  />
                </li>
                <li className=" ml-4 md:ml-0 md:mr-6">
                  <img
                    src={knot2018.childImageSharp.fluid.src}
                    alt="The Knot - Best of Weddings Award 2018"
                  />
                </li>
                <li className=" ml-4 md:ml-0 md:mr-6">
                  <img
                    src={knot2019.childImageSharp.fluid.src}
                    alt="The Knot - Best of Weddings Award 2019"
                  />
                </li>
                <li className=" ml-4 md:ml-0 md:mr-6">
                  <img
                    src={knot2020.childImageSharp.fluid.src}
                    alt="The Knot - Best of Weddings Award 2020"
                  />
                </li>
              </ul>
            </div>
          </BackgroundImage>
          </>
        ) : (
          <>
          <FASC />
            <BackgroundImage
              Tag="div"
              className="border-b-8 border-green-800 h-header flex flex-col"
              fluid={
                headerImage
                  ? headerImage.childImageSharp.fluid
                  : desktop.childImageSharp.fluid
              }
              backgroundColor={`#040e18`}
            >
              <div className="bg-vignette flex-grow md:pt-8">
                <MobileHeader />
                <div className="container mx-auto hidden md:flex items-center">
                  {/* <img src={wbLogo.childImageSharp.fluid.src} alt="Willow Branch Flowers and Design Logo"/> */}
                  <nav className="container mx-auto justify-end flex">
                    <HeaderNav />
                  </nav>
                </div>
              </div>
            </BackgroundImage>
          </>
        )}
      </header>
      <main className="bg-white flex-grow">
        {children}

        <section className="py-12 px-4 md:px-0">
          <div className="container mx-auto">
            <h4 className="text-center font-serif text-2xl mb-8">
              Client Testimonials
            </h4>
            <div className="md:flex mx-auto text-sm">
              <div className="w-full md:w-1/2 md:pr-8 mb-8 md:mb-0">
                <blockquote className="font-serif leading-loose italic">
                  <p>"{randomTestimonials[0].quote}"</p>
                  <cite className="font-sans not-italic">
                    - {randomTestimonials[0].customer}
                  </cite>
                </blockquote>
              </div>
              <div className="w-full md:w-1/2 md:pl-8">
                <blockquote className="font-serif leading-loose italic">
                  <p>"{randomTestimonials[1].quote}"</p>
                  <cite className="font-sans not-italic">
                    - {randomTestimonials[1].customer}
                  </cite>
                </blockquote>
              </div>
            </div>
          </div>
          <div id="wp-widget-reviews" className="text-center mt-8 text-sm">
            <div id="wp-widget-preview">
              Find More Client Testimonials and Reviews at
              <a
                href="https://www.weddingwire.com/wedding-florists/willow-branch-flowers-and-design--e44254/reviews"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://cdn1.weddingwire.com/assets/img/logos/gen_logoHeader.svg"
                  height="20"
                  alt="Wedding Wire Logo"
                  className="block mx-auto"
                />
              </a>
            </div>
          </div>
        </section>
      </main>
      <footer className="text-green-200 py-12">
        <div className="container mx-auto flex flex-wrap">
          <div className="w-1/2 hidden md:block">
            <nav>
              <ul className="flex">
                <li>
                  <Link
                    className="inline-block py-2 px-4 rounded hover:bg-green-700"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className="inline-block py-2 px-4 rounded hover:bg-green-700"
                    to="/brides"
                  >
                    Our Work
                  </Link>
                </li>
                <li>
                  <Link
                    className="inline-block py-2 px-4 rounded hover:bg-green-700"
                    to="/testimonials"
                  >
                    Testimonials
                  </Link>
                </li>
                <li>
                  <Link
                    className="inline-block py-2 px-4 rounded hover:bg-green-700"
                    to="/about"
                  >
                    About
                  </Link>
                </li>
                <li>
                  <Link
                    className="inline-block py-2 px-4 rounded hover:bg-green-700"
                    to="/contact"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="w-full md:w-1/2 text-sm text-center md:text-right">
            &copy; {new Date().getFullYear} Willow Branch Flowers &amp; Design,
            All Rights Reserved.
          </div>
          <div className="w-full text-center md:text-right text-xs pt-2 md:pt-0">
            <a
              href="https://www.systemsevendesigns.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mooresville web site design by systemseven
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
